(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name neo.directive:neoDownloadCsvTable
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="neo">
   <file name="index.html">
   <neo-download-csv-table></neo-download-csv-table>
   </file>
   </example>
   *
   */
  angular
    .module('neo')
    .directive('neoDownloadCsvTable', neoDownloadCsvTable);

  function neoDownloadCsvTable() {
    return {
      restrict: 'E',
      scope: {
        htmlTable: '=',
        filename: '@'
      },
      templateUrl: 'directives/neo-download-csv-table/neo-download-csv-table-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope, $filter, HTMLToCSVParser) {
        var vm = this;
        vm.name = 'neoDownloadCsvTable';

        vm.exportToCsv = function () {
          var csvString = HTMLToCSVParser.parse($scope.htmlTable);
          var completeFilename = $filter('translate')($scope.filename) + '.csv';
          if (window.navigator.msSaveBlob) { // IE
            var blob = new Blob(["\ufeff" + csvString], {type: "text/csv;charset=utf-8;"});
            window.navigator.msSaveOrOpenBlob(blob, completeFilename)
          } else { // Chrome and Firefox
            var anchor = angular.element('<a/>');
            anchor.css({display: 'none'});
            angular.element(document.body).append(anchor);
            anchor.attr({
              href: 'data:application/octet-stream;base64,' + btoa(csvString),
              target: '_blank',
              download: completeFilename
            })[0].click();
            anchor.remove();
          }
        };
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
